<template>
  <div class="row m-0">
    <div class="col-lg-2 ml-0 pl-0" style="height: calc(100vh - 113px)">
      <h5 class="mb-1 mt-2 text-center">
        <strong>Informes</strong>
      </h5>
      <ul class="item-taller pl-0 mb-0">
        <li
          class="cursor-pointer mb-2"
          @click="cambio(1)"
          :class="indicador == 1 ? 'item-taller-activo' : ''"
        >
          <p class="py-1 text-left pl-3">
            <font-awesome-icon icon="building" class="estilo-icono" />Empresa
          </p>
        </li>
        <li
          class="cursor-pointer mb-2"
          @click="cambio(2)"
          :class="indicador == 2 ? 'item-taller-activo' : ''"
        >
          <p class="py-1 text-left pl-3">
            <font-awesome-icon icon="network-wired" class="estilo-icono" />Tipo
            de taller
          </p>
        </li>
        <li
          class="cursor-pointer mb-2"
          @click="cambio(3)"
          :class="indicador == 3 ? 'item-taller-activo' : ''"
        >
          <p class="py-1 text-left pl-3">
            <font-awesome-icon
              icon="map-marked-alt"
              class="estilo-icono"
            />Ciudad
          </p>
        </li>
        <li
          class="cursor-pointer mb-2"
          @click="cambio(4)"
          :class="indicador == 4 ? 'item-taller-activo' : ''"
        >
          <p class="py-1 text-left pl-3">
            <font-awesome-icon icon="calendar-alt" class="estilo-icono" />Fecha
          </p>
        </li>
        <li
          class="cursor-pointer mb-2"
          @click="cambio(5)"
          :class="indicador == 5 ? 'item-taller-activo' : ''"
        >
          <p class="py-1 text-left pl-3">
            <font-awesome-icon icon="user-tie" class="estilo-icono" />Formadores
          </p>
        </li>
      </ul>
    </div>
    <loading class="col-lg-10 p-0" v-if="estadoloading" />
    <div class="col-lg-10" v-else>
      <div
        class="border mt-2 container-fluid"
        style="height: calc(100vh - 150px)"
      >
        <h5 class="mt-2">
          <strong>Historial de Talleres</strong>
        </h5>
        <div class="tabla">
          <table class="titulos">
            <thead>
              <tr>
                <th>#</th>
                <th>Taller</th>
                <th>Fecha</th>
                <th>Ciudad</th>
                <th>Empresa</th>
                <th>Participantes</th>
                <th>Formador1</th>
                <th>Formador2</th>
                <th>Formador3</th>
                <th>Formador4</th>
              </tr>
            </thead>
          </table>
          <div class="cuerpo">
            <table>
              <tbody>
                <tr
                  v-for="(taller, index) in filterTalleres"
                  :key="taller.id"
                  v-if="taller.estadoTaller.id == 3"
                >
                  <td>#{{ index + 1 }}</td>
                  <td @click="view(taller.id)" class="cursor-pointer">
                    {{ taller.tipoTaller.nombre }}
                  </td>
                  <td>{{ taller.fecha }}</td>
                  <td>{{ taller.ciudad.nombre }}</td>
                  <td>{{ taller.empresa.nombre }}</td>
                  <td>{{ taller.cantPersona }}</td>
                  <td v-if="taller.formadoruno.fullname != undefined">
                    <p
                      class="mb-0 cursor-pointer"
                      @click="
                        generearCalificacion(
                          taller.id,
                          taller.formadoruno.id
                        )
                      "
                    >
                      {{ taller.formadoruno.fullname }}
                    </p>
                  </td>
                  <td v-else>---------</td>
                  <td v-if="taller.formadordos.fullname != undefined">
                    <p
                      class="mb-0 cursor-pointer"
                      @click="
                        generearCalificacion(
                          taller.id,
                          taller.formadordos.id
                        )
                      "
                    >
                      {{ taller.formadordos.fullname }}
                    </p>
                  </td>
                  <td v-else>---------</td>
                  <td v-if="taller.formadortres.fullname != undefined">
                    <p
                      class="mb-0 cursor-pointer"
                      @click="
                        generearCalificacion(
                          taller.id,
                          taller.formadortres.id
                        )
                      "
                    >
                      {{ taller.formadortres.fullname }}
                    </p>
                  </td>
                  <td v-else>---------</td>
                  <td v-if="taller.formadorcuatro.fullname != undefined">
                    <p
                      class="mb-0 cursor-pointer"
                      @click="
                        generearCalificacion(
                          taller.id,
                          taller.formadorcuatro.id
                        )
                      "
                    >
                      {{ taller.formadorcuatro.fullname }}
                    </p>
                  </td>
                  <td v-else>---------</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL FILTRO -->
    <div class="modal" tabindex="-1" role="dialog" id="filtrarModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">{{ tipoFiltro }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="cerrar()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group" v-if="indicador == 1">
              <v_select
                placeholder="Seleccione una empresa"
                label="nombre"
                :options="empresas"
                v-model="empresa"
              />
            </div>
            <div class="form-group" v-if="indicador == 2">
              <v_select
                placeholder="Seleccione un tipo de taller"
                label="nombre"
                :options="tipoTalleres"
                v-model="tipo_taller"
              />
            </div>
            <div class="form-group" v-if="indicador == 3">
              <v_select
                placeholder="Seleccione una ciudad"
                label="nombre"
                :options="ciudades"
                v-model="ciudad"
              />
            </div>
            <div class="form-group" v-if="indicador == 4">
              <datePicker :config="options" v-model="fecha" />
            </div>
            <div class="form-group" v-if="indicador == 5">
              <v_select
                placeholder="Seleccione un formador"
                label="fullname"
                :options="formadores"
                v-model="formador"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click.prevent="cerrar()"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="filtrar"
            >
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import loading from "@/components/Loading";
import v_select from "vue-select";
import "vue-select/dist/vue-select.css";
// Import required dependencies
import "bootstrap/dist/css/bootstrap.css";
// Import this component
import datePicker from "vue-bootstrap-datetimepicker";
// Import date picker css
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import axios from "axios";
export default {
  name: "informe",
  data() {
    return {
      empresa: "",
      tipo_taller: "",
      ciudad: "",
      fecha: "",
      formador: "",
      indicador: 0,
      tipoFiltro: "",
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
    };
  },
  computed: {
    talleres() {
      return this.$store.getters.getTalleres;
    },
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
    ciudades() {
      return this.$store.getters.getCiudades;
    },
    empresas() {
      return this.$store.getters.getEmpresas;
    },
    tipoTalleres() {
      return this.$store.getters.getTipoTalleres;
    },
    formadores() {
      return this.$store.getters.getFormadores;
    },
    filterTalleres() {
      switch (this.indicador) {
        case 1:
          if (this.empresa.id != undefined || this.empresa.id != null) {
            let filtro = this.talleres.filter((taller) => {
              return taller.empresa.nombre
                .toLowerCase()
                .match(this.empresa.nombre.toLowerCase());
            });
            return filtro;
          } else {
            return this.talleres;
          }
          break;
        case 2:
          if (this.tipo_taller.id != undefined || this.tipo_taller.id != null) {
            let filtro = this.talleres.filter((taller) => {
              return taller.tipoTaller.nombre
                .toLowerCase()
                .match(this.tipo_taller.nombre.toLowerCase());
            });
            return filtro;
          } else {
            return this.talleres;
          }
          break;
        case 3:
          if (this.ciudad.id != undefined || this.ciudad.id != null) {
            let filtro = this.talleres.filter((taller) => {
              return taller.ciudad.nombre
                .toLowerCase()
                .match(this.ciudad.nombre.toLowerCase());
            });
            return filtro;
          } else {
            return this.talleres;
          }
          break;
        case 4:
          if (this.fecha != "") {
            let filtro = this.talleres.filter((taller) => {
              return taller.fecha.match(this.fecha);
            });
            return filtro;
          } else {
            return this.talleres;
          }
          break;
        case 5:
          if (this.formador.id != undefined || this.formador.id != null) {
            let filtro = this.talleres.filter((taller) => {
              if (
                taller.formadoruno.id != undefined &&
                taller.formadoruno.id == this.formador.id
              ) {
                return taller.formadoruno.fullname
                  .toLowerCase()
                  .match(this.formador.fullname.toLowerCase());
              } else if (
                taller.formadordos.id != undefined &&
                taller.formadordos.id == this.formador.id
              ) {
                return taller.formadordos.fullname
                  .toLowerCase()
                  .match(this.formador.fullname.toLowerCase());
              } else if (
                taller.formadortres.id != undefined &&
                taller.formadortres.id == this.formador.id
              ) {
                return taller.formadortres.fullname
                  .toLowerCase()
                  .match(this.formador.fullname.toLowerCase());
              } else if (
                taller.formadorcuatro.id != undefined &&
                taller.formadorcuatro.id == this.formador.id
              ) {
                return taller.formadorcuatro.fullname
                  .toLowerCase()
                  .match(this.formador.fullname.toLowerCase());
              }
            });
            return filtro;
          } else {
            return this.talleres;
          }
          break;
        default:
          return this.talleres;
          break;
      }
    },
  },
  components: {
    loading,
    v_select,
    datePicker,
    VueTimepicker,
  },
  mounted() {
    this.$store.dispatch("fetchTalleres");
  },
  methods: {
    cambio(index) {
      switch (index) {
        case 1:
          this.indicador = index;
          this.tipoFiltro = "Empresa";
          $("#filtrarModal").modal("show");
          break;
        case 2:
          this.indicador = index;
          this.tipoFiltro = "Tipo Taller";
          $("#filtrarModal").modal("show");
          break;
        case 3:
          this.indicador = index;
          this.tipoFiltro = "Ciudad";
          $("#filtrarModal").modal("show");
          break;
        case 4:
          this.indicador = index;
          this.tipoFiltro = "Fecha";
          $("#filtrarModal").modal("show");
          break;
        case 5:
          this.indicador = index;
          this.tipoFiltro = "Formador";
          $("#filtrarModal").modal("show");
          break;

        default:
          break;
      }
    },
    cerrar() {
      this.limpiar();
      $("#filtrarModal").modal("hide");
    },
    limpiar() {
      this.empresa = "";
      this.tipo_taller = "";
      this.ciudad = "";
      this.fecha = "";
      this.formador = "";
      this.indicador = 0;
    },
    filtrar() {
      $("#filtrarModal").modal("hide");
    },
    generearCalificacion(taller, usuario) {
      let element = this;
      Swal.fire({
        title: "Quiere descargar la calificación del formador?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Descargar",
      }).then((result) => {
        if (result.value) {
          let datos = {
            user_id: usuario,
            taller_id: taller,
            token: element.$store.getters.getUserAuth.access_token,
          };
          axios
            .post(
              process.env.VUE_APP_WEBSERVICE_URL + "/generarEvaluacion",
              datos
            )
            .then((response) => {
              if (!_.has(response.data, "STATUS_CODE")) {
                console.error("Error de conexión");
              }
              if (response.data.DESCRIPTION.toLowerCase() === 'error') {
                Swal.fire("Información!", response.data.DATA, "info");
              } else {
                var a = document.createElement("a");
                var url =
                  process.env.VUE_APP_WEBSERVICE_URL +
                  "/archivo/" +
                  response.data.DATA +
                  "/descargar";
                a.href = url;
                a.download;
                a.target = "_blank";
                a.click();
                window.URL.revokeObjectURL(url);
                Swal.fire("Felicidades!", "El archivo se a generado.", "success");
              }
              
            })
            .catch((error) => {
              console.error("Error de conexión");
              return Promise.reject(error.response.data);
            });
        }
      });
    },
    view(id) {
      this.$router.push({ name: "viewEvaluacion", params: { id: id } });
    },
  },
};
</script>
<style lang="css">
.estilo-icono {
  font-size: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
</style>
